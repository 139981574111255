import React from 'react';
import Projects from '../components/projects/index'; // Import the index.js file

const ProjectPage = () => {
  return (
    <>
      <Projects /> 
    </>
  );
};

export default ProjectPage;