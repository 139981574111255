import React from 'react'
import styled from 'styled-components'


export const ProjectsContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column; 
  align-items: center;
  background: black;
  padding-top: 20px;
  padding-bottom: 64px; 
  overflow-y: auto;

`;

export const ProjectsWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* changed to repeat(3, 1fr) to make 3 columns */
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr); /* changed to repeat(2, 1fr) to make 2 columns */
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr; /* changed to 1fr to make 1 column */
    padding: 0 20px;
  }
`

export const ProjectsCard = styled.div`
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 340px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`

export const ProjectsIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
`

export const ProjectsH1 = styled.h1`
    font-size: 2.5rem;
    color: white;
    margin-bottom: 64px;

    @media screen and (max-width: 480px){
        font-size: 2rem;
    }
`

export const ProjectsH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
`

export const ProjectsP = styled.p`
    font-size: 1rem;
    text-align: center;
`