import React from 'react'
import { Button, DownloadButton } from '../ButtonElement'
import { AboutContainer, AboutWrapper, AboutRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img, } from './aboutElements'
import { Link as LinkRoute } from 'react-router-dom' 

import { homeObjThree } from './Data'
// specify in order to download a pdf instead of going to another page

const AboutSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, img, alt, primary, dark, dark2, link}) => {
  

  return (
    <>
      <AboutContainer lightBg={lightBg} id={id}>
        <AboutWrapper> 
          <AboutRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <BtnWrap>
                {link === homeObjThree.link ? (
                    <DownloadButton
                      href={link}
                      download
                      primary={primary ? 1 : 0}
                      dark={dark ? 1 : 0}
                      dark2={dark2 ? 1 : 0}
                    >
                      {buttonLabel}
                    </DownloadButton>
                  ) : (
                    <Button
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact={true}
                      offset={-80}
                      primary={primary ? 1 : 0}
                      dark={dark ? 1 : 0}
                      dark2={dark2 ? 1 : 0}
                    > 
                      <LinkRoute to={link} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {buttonLabel}
                      </LinkRoute>
                    </Button>
                  )}
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt}/>
              </ImgWrap>
            </Column2>
          </AboutRow>
        </AboutWrapper>
      </AboutContainer>
    </>
  )
}

export default AboutSection