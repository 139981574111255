import React from 'react'
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink } from './FooterElements'
import { SocialMedia, SocialMediaWrap, SocialLogo, SocialIcons, SocialIconLink } from './FooterElements'
import {FaInstagram, FaLinkedin, FaGithub, FaJava, FaPython, FaReact, FaJs} from 'react-icons/fa'

const Footer = () => {
    return(
    <FooterContainer>
        <FooterWrap>
        <FooterLinksContainer>
            <FooterLinksWrapper>
                <FooterLinkItems>
                    <FooterLinkTitle> You can find me at </FooterLinkTitle>
                        <FooterLink to ="https://github.com/lwinger17" target="_blank" aria-label = "Github">Github</FooterLink>
                        <FooterLink to ="https://concordia.joinhandshake.com/profiles/34574275" target="_blank" aria-label = "Handshake">Handshake</FooterLink>
                        <FooterLink to ="https://www.linkedin.com/in/lucas-winger-3842112a3/" target="_blank" aria-label = "LinkedIn">LinkedIn</FooterLink>
                        <FooterLink to ="https://www.instagram.com/lucaswinger/" target="_blank" aria-label = "Instagram">Instagram</FooterLink>
                </FooterLinkItems>
                <FooterLinkItems>
                    <FooterLinkTitle> Links to my projects  </FooterLinkTitle>
                        <FooterLink to ="https://github.com/lwinger17/Blackjack-Project" target="_blank" aria-label = "Blackjack">Blackjack</FooterLink>
                        <FooterLink to ="https://github.com/lwinger17/Hand-Tracking-Distance-" target="_blank" aria-label = "GitHand">Hand tracking</FooterLink>
                        <FooterLink to ="https://replit.com/@lwinger" target="_blank" aria-label = "Replit">100 Days of Python</FooterLink>
                </FooterLinkItems>
                <FooterLinkItems>
                <FooterLinkTitle> My Languages </FooterLinkTitle>
                    <FaJava size={26} style={{ color: '#007bff' }} />
                    <FaPython size={26} style={{ color: '#3776ab' }} />
                    <FaReact size={26} style={{ color: '#61dafb' }} />
                    <FaJs size={26} style={{ color: '#f7dc6f' }} />
            
                    </FooterLinkItems>
            </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
            <SocialMediaWrap>
                <SocialLogo to = '/'>
                    Winger 
                </SocialLogo>
                <SocialIcons>
                    <SocialIconLink to ="//www.linkedin.com/in/lucas-winger-3842112a3/" target="_blank" aria-label = "LinkedIn">
                    <FaLinkedin/></SocialIconLink>
                    <SocialIconLink to ="//github.com/lwinger17" target="_blank" aria-label = "Github">
                    <FaGithub/></SocialIconLink>
                    <SocialIconLink to ="//www.instagram.com/lucaswinger/" target="_blank" aria-label = "Instagram">
                    <FaInstagram/></SocialIconLink>
                </SocialIcons>
            </SocialMediaWrap>
        </SocialMedia>
        </FooterWrap>
    </FooterContainer>
    )
}

export default Footer