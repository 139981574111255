import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute } from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen = {isOpen} onClick = {toggle}>
            <Icon onClick = {toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to = "about" onClick = {toggle}> About </SidebarLink>
                    <SidebarLink to = "projects" onClick = {toggle}> Projects </SidebarLink>
                    <SidebarLink to = "resume" onClick = {toggle}> Resume </SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to = {`mailto:${"lucas17winger@gmail.com"}?subject=Message from Portfolio`} target="_blank"> Message Me </SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar