import { FaLessThanEqual } from 'react-icons/fa6';
import conc from '../../images/conc.png';
import svg1 from '../../images/svg-1-bio.svg'
import svg2 from '../../images/svg-2-bio.svg'
import svg3 from '../../images/svg-3-bio.svg'
import svg4 from '../../images/svg-4-bio.svg'

export const homeObjOne = {
  id: 'education',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Education',
  headline: 'Concordia College',
  description: 'I went to school at Concordia College in Moorhead, MN which is a private liberal arts college affiliated with the ELCA. I came to this school in 2021 and I have enjoyed learning from this campus with their amazing professors, great food, and the small classes that allowed for easier 1 on 1 discussions with professors.',
  imgStart: true,
  img: conc, 
  alt: 'school',
  dark: false,
  primary: false,
  darkText: true,
}

export const homeObjTwo = {
  id: 'story',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Growth',
  headline: 'Why Comp-sci?',
  description: 'When I first came to Concordia I declared my major as undeclared and decided to take some Pre-med classes as I thought I would be interested in medicine. However While I managed to pass my classes I felt no love from it, so my advisor suggested taking a coding class because of my love for technology. After taking that class it finally clicked when I was working with a group to make a blackjack game.',
  imgStart: false,
  img: svg1,
  alt: 'growth',
  dark: true,
  primary: true,
  darkText: false
}

export const homeObjThree = {
  id: 'type',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Developer',
  headline: 'What kind of Developer do I plan to be?',
  description: 'I am hoping to learn React Native in order to build mobile applications as I believe more apps are going to be created. However I would not mind furthering my learning into Python or Website Development.',
  imgStart: true,
  img: svg2,
  alt: 'love',
  dark: false,
  primary: false,
  darkText: true
}

export const homeObjFour = {
    id: 'type',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Type',
    headline: 'What Kind of Person am I?',
    description: "The best way to describe me is a morning person who loves to get work done right away. I'm the kind of guy that ends up making a schedule for the day and when the day gets off schedule I just end up going with the flow.",
    imgStart: false,
    img: svg3, //
    alt: '',
    dark: true,
    primary: true,
    darkText: false
  }

  export const homeObjFive = {
    id: 'fun',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Fun',
    headline: 'What do I do for fun?',
    description: 'For my whole life I have always enjoyed video games, golf, basketball, going to events, and hanging out with friends. And after learning how to code I honestly could say that this is something I can do for fun. ',
    imgStart: true,
    img: svg4,
    alt: 'res',
    dark: false,
    primary: false,
    darkText: true
  }