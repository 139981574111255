import React from 'react'
import {Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks} from './NavBarElements';
import {NavBtn, NavBtnLink} from './NavBarElements';
import {FaBars, FaRoad} from 'react-icons/fa'

// mailto: brings the user to whatever mailing app they use, works for mobile and desktop

const Navbar = ({toggle}) => {
    return (
    <>
    <Nav>
      <NavbarContainer>
        <NavLogo to = '/'> Portfolio</NavLogo>
        <MobileIcon onClick = {toggle}>
            <FaBars />
        </MobileIcon>
        <NavMenu>
            <NavItem>
                <NavLinks to = 'about' smooth={true} duration={500}> About</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to = 'projects' smooth={true} duration={500}> Projects</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to = 'resume' smooth={true} duration={500}> Resume</NavLinks>
            </NavItem>
        </NavMenu>
        <NavBtn>
            <NavBtnLink to = {`mailto:${"lucas17winger@gmail.com"}?subject=Message from Portfolio`} target="_blank"> Message Me </NavBtnLink>
        </NavBtn>
      </NavbarContainer>
   </Nav>
    </>
    )
}

export default Navbar;