import React from 'react';
import BioSection from '../components/bio'
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour, homeObjFive } from '../components/bio/Data';

const BioPage = () => {
  return (
    <>
      <BioSection {...homeObjOne}/>
      <BioSection {...homeObjTwo}/>
      <BioSection {...homeObjThree}/> 
      <BioSection {...homeObjFour}/> 
      <BioSection {...homeObjFive}/> 
    </>
  );
};

export default BioPage;