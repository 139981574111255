import React, {useState} from 'react'
import HeroSection from '../components/HeroSection';
import Sidebar from '../components/SideBar';
import Navbar from '../components/NavBar';
import AboutSection from '../components/AboutSection'
import {homeObjOne, homeObjTwo, homeObjThree} from '../components/AboutSection/Data'
import Footer from '../components/Footer';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }


    return (
        <>
        <Sidebar isOpen = {isOpen} toggle = {toggle}/>
        <Navbar toggle = {toggle} />
        <HeroSection />
        <AboutSection {...homeObjOne}/>
        <AboutSection {...homeObjTwo}/>
        <AboutSection {...homeObjThree}/>
        <Footer />
        </>
    )
}

export default Home;