import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./styles.css";

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <App />
      <body>
      <noscript>
        You need to enable JavaScript to run this app....sorry
      </noscript>
      <div id="root"></div>
      <script src="bundle.js"></script>
    </body>
  </React.StrictMode>
)

//if body doesnt work remove
reportWebVitals();
