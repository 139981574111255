import React, {useState} from 'react'
import Video from '../../video/video.mp4'
import {HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight} from './HeroElements'
import { MailButton } from '../ButtonElement'

// fix link to email page
const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }
    return (
        <HeroContainer id = "home">
            <HeroBg>
                <VideoBg autoPlay loop muted src = {Video} type = 'video/mp4' />
            </HeroBg>
            <HeroContent>
            <HeroH1> Hi! I'm Lucas Winger</HeroH1>
            <HeroP> 
                I'm a Computer Science undergradute who is interested in making an impact in the world
            </HeroP>
            <HeroBtnWrapper>
                <MailButton href = {`mailto:${"lucas17winger@gmail.com"}?subject=Message from Portfolio`} // email just for this website
                target="_blank" onMouseEnter = {onHover} 
                onMouseLeave = {onHover}
                primary= 'true'
                dark = 'true'
                >
                    Message Me {hover ? <ArrowForward /> : <ArrowRight/>}
                </MailButton>
            </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection;