import svg3 from '../../images/svg-3.svg';
import svg2 from '../../images/svg-2.svg';
import personal from '../../images/personal.png';


export const homeObjOne = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Lucas Winger',
  headline: 'Who am I?',
  description: 'I am 22 currently living in Fargo, ND for education who is very passionate about creating things in a digital space',
  buttonLabel: 'learn more',
  link: '/about', 
  imgStart: true,
  img: personal, 
  alt: 'me',
  dark: false,
  primary: false,
  darkText: true,
}

export const homeObjTwo = {
  id: 'projects',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Projects',
  headline: 'What have I made?',
  description: 'Once I switched my major to Comp Sci, I immediatly had the urge to create and learn how certain things worked, with my already busy schedule.',
  buttonLabel: 'Take a Look',
  link: '/projects',
  imgStart: false,
  img: svg2,
  alt: 'proj',
  dark: true,
  primary: true,
  darkText: false
}

export const homeObjThree = {
  id: 'resume',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Resume',
  headline: 'Like what you see?',
  description: 'If you like what you see I first off want to thank you for looking through this, I hope to improve in both design and development as I continue to progress in my journey.',
  buttonLabel: 'Download',
  link:`/resume.pdf`,
  imgStart: true,
  img: svg3,
  alt: 'res',
  dark: false,
  primary: false,
  darkText: true
}