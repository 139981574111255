import styled from 'styled-components'
import { Link } from 'react-scroll'

export const Button = styled(Link)`
    border-radius: 50px;
    white-space: nowrap;
    background: ${({ primary }) => (primary ? 'black': '#009999')}; 
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? 'white' : 'black')};
    font-size : ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline : none;
    border : none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ? 'black' : '#009999')};
        color: ${({ primary }) => (primary ? '#009999' : 'white')};
    }
`


export const MailButton = styled.a`
  border-radius: 50px;
  white-space: nowrap;
  background: ${({ primary }) => (primary ? 'black': '#009999')}; 
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  color: ${({dark}) => (dark ? 'white' : 'black')};
  font-size : ${({fontBig}) => (fontBig ? '20px' : '16px')};
  outline : none;
  border : none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? 'black' : '#009999')};
    color: ${({ primary }) => (primary ? '#009999' : 'white')};
  }
`;

export const DownloadButton = styled.a`
  border-radius: 50px;
  white-space: nowrap;
  background: ${({ primary }) => (primary ? 'black': '#009999')}; 
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  color: ${({dark}) => (dark ? 'white' : 'black')};
  font-size : ${({fontBig}) => (fontBig ? '20px' : '16px')};
  outline : none;
  border : none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? 'black' : '#009999')};
    color: ${({ primary }) => (primary ? '#009999' : 'white')};
  }
`;