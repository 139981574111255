import React from 'react';
import './styles.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './pages';
import Navbar from './components/NavBar';
import Sidebar from './components/SideBar';
import Footer from './components/Footer';
import ProjectPage from './pages/project';
import BioPage from './pages/bio'

// creates a seperate nav and sidebar so it doesn't appear twice for home
function Layout({ children }) {
  return (
    <>
      <Navbar />
      <Sidebar />
      {children}
      <Footer/>
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Layout><ProjectPage /></Layout>} />
        <Route path="/about" element={<Layout><BioPage /></Layout>} />
        
      </Routes>
    </Router>
  );
}

export default App;