import React from 'react'
import { ProjectsContainer, ProjectsH1, ProjectsH2, ProjectsIcon, ProjectsCard, ProjectsWrapper, ProjectsP } from './projectElements'
import Icon1 from '../../images/hand.png'
import Icon2 from '../../images/card.png'
import Icon3 from '../../images/100.png'
import Icon4 from '../../images/web.png'
import Icon5 from '../../images/future.png'
//maybe add button to source code?

const projects = () => {
    return (
    <ProjectsContainer id = "projects">
    
        <ProjectsWrapper>
        <ProjectsH1> My Projects</ProjectsH1>
        <ProjectsH2 style ={{color: 'white'}}> The source codes can be found in the footer</ProjectsH2>
            <ProjectsCard>
                <ProjectsIcon src={Icon1}/>
                <ProjectsH2> AI Hand Distance Detection</ProjectsH2>
                <ProjectsP> For this project I created python code that identifies your left and right hand while showing the distance of them from the camera. For this I used OpenCV library for image and video processing and the Mediapipe framework for using their machine learning tools.</ProjectsP>
            </ProjectsCard>
            <ProjectsCard>
                <ProjectsIcon src={Icon2}/>
                <ProjectsH2> Blackjack </ProjectsH2>
                <ProjectsP> This project uses Java to create a fleshed out game with an interactable menus, rules on how to play, interactable buttons that allow you to do different functions in blackjack, and It can even be switched to the French language.</ProjectsP>
            </ProjectsCard>
            <ProjectsCard>
                <ProjectsIcon src={Icon3}/>
                <ProjectsH2> 100 Days of Python</ProjectsH2>
                <ProjectsP> I decided to do this at the start of the summer of 2024 where I did a daily project for 100 days straight. In this project I created a lot of things such as an inventory manager, to do list, tic tac toe, spotify year search, and many more.</ProjectsP>
            </ProjectsCard>
            <ProjectsCard>
                <ProjectsIcon src={Icon4}/>
                <ProjectsH2> This Website</ProjectsH2>
                <ProjectsP> I started to work on this the second half of the summer of 2024 because I learned about React and I was interested in learning it, so I watched some videos teaching about it and ended up making this website.</ProjectsP>
            </ProjectsCard>
            <ProjectsCard>
                <ProjectsIcon src={Icon5}/>
                <ProjectsH2> To be Continued..</ProjectsH2>
                <ProjectsP> In the future I hope to work on more React projects or get into React Native to develop a mobile application that can be used on android and apple.</ProjectsP>
            </ProjectsCard>
        </ProjectsWrapper>
    </ProjectsContainer>
    )
}

export default projects;